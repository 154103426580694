import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';

const Contact = ({ referencedNumber, emailAdaugatDe,productTitle }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    referencedNumber: '',
    title: '',
    name: '',
    email: '',
    telephone: '',
    agreeToTerms: false,
    productTitle: '',
    mesaj:''
  });

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Update formData when referencedNumber or emailAdaugatDe changes
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      referencedNumber,
      emailAdaugatDe,
      productTitle,
    }));
  }, [referencedNumber, productTitle, emailAdaugatDe]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      const response = await fetch('https://autodealsts.co.uk/apiiiwebsite/save_contact_from_product.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      console.log('Form submitted successfully:', data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
    }
  };

  return (
    <div>
      <p>{t('productcontact')}</p>
      {!submitted ? (
        <form
          onSubmit={handleSubmit}
          style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            
            <div style={{ width: '48%' }}>
            <TextField
              fullWidth
              id="name"
              label={t('Name')}
              variant="outlined"
              required
              value={formData.name}
              onChange={handleChange}
              style={{ marginBottom: '10px' }}
            />
          </div>
            <div style={{ width: '48%',display:'none'    }}>
              <TextField
                fullWidth
                id="emailAdaugatDe"
                label={t('Email Added By')}
                variant="outlined"
                required
                value={formData.emailAdaugatDe}
                onChange={handleChange}
                style={{ marginBottom: '10px', display:'none' }}
                disabled
              />
            </div>
            <div style={{ width: '48%' }}>
              <TextField
                fullWidth
                id="telephone"
                label={t('Telephone')}
                variant="outlined"
                required
                value={formData.telephone}
                onChange={handleChange}
                style={{ marginBottom: '10px' }}
              />
            </div>
            <div style={{ width: '48%' }}>
              <TextField
                fullWidth
                type="email"
                id="email"
                label={t('Email')}
                variant="outlined"
                required
                value={formData.email}
                onChange={handleChange}
                style={{ marginBottom: '10px' }}
              />
            </div>
          </div>
          <div style={{ width: '100%' }}>
              <TextField
                fullWidth
                id="productTitle"
                label={t('Subject')}
                variant="outlined"
                required
                value={formData.productTitle}
                onChange={handleChange}
                style={{ marginBottom: '10px', display:''}}
                disabled
              />
            </div>
            <textarea 
  id="mesaj" 
  value={formData.mesaj} // controlled component using value
  onChange={(e) => setFormData({ ...formData, mesaj: e.target.value })} // handler to update the formData state
  style={{ width: '60%', marginBottom: '10px', padding: '5px' }} 
  placeholder='Enter your message'
  rows={7} 
/>

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
           
            
          </div>
          <div style={{ fontSize: '14px' }}>{t('textFormProduct')}</div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox id="agreeToTerms" checked={formData.agreeToTerms} onChange={handleChange} />
              }
              label={t('Informed and agreeable*')}
              style={{ marginBottom: '10px' }}
            />
          </div>
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
          <Button
            type="submit"
            id="buttonsubmit"
            variant="contained"
            color="primary"
            style={{
              width: '50%',
              background: '#ff3232',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {t('Submit')}
          </Button>
        </form>
      ) : (
        <Typography variant="h6" color="success.main">
          {t('Form submitted successfully!')}
        </Typography>
      )}
    </div>
  );
};

export default Contact;
