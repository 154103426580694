import React, { useState } from 'react';
import Container from '@mui/material/Container';
import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import './Reviews.css';
// Generate 30 static reviews
const generateStaticReviews = () => {
 
    const names = [
        'John Doe', 'Jane Smith', 'Alice Johnson', 'Emily Brown', 'Oliver Twist',
        'Harry Green', 'Sophia White', 'Liam Black', 'Noah Silver', 'James Gold',
        'Lily Orange', 'Ava Violet', 'Charlotte Grey', 'Isabella Red', 'Mia Blue',
        'Amelia Pink', 'Ella Yellow', 'Jack Rose', 'Jacob Brown', 'William Indigo',
        'David Clifford', 'Olivia Bronze', 'Lucas Purple', 'Henry Copper', 'George Turquoise',
        'Henry Plum', 'Ella Green', 'Benjamin Stone', 'Grace White', 'Hannah Steele',
        'Alexander Gold', 'Victoria Amber', 'Samuel Forest', 'Chloe Sands', 'Dylan Slate',
        'Ruby Crimson', 'Nathaniel Sky', 'Penelope Mauve', 'Mason Marble', 'Eliza Silver',
        'Scarlett Stone', 'Ethan Shadow', 'Luna Slate', 'Jack Black', 'Matthew Cliff',
        'Sophia Grey', 'George Iron', 'Daniel Frost', 'Olivia Moon', 'Arthur Stone',
        'Emily Wood', 'Nathan Grey'
    ];
    
  const locations = [
    'London, UK',
    'Edinburgh, Scotland',
    'Glasgow, Scotland',
    'Hardyards, Mintlaw, Peterhead, Scotland, AB42 4EY',
    'Aberdeen, Scotland',
    'Dundee, Scotland',
    'Inverness, Scotland',
    'Stirling, Scotland',
    'Perth, Scotland',
    'Falkirk, Scotland',
    'Ayr, Scotland',
    'Kilmarnock, Scotland',
    'Dumfries, Scotland',
    'Livingston, Scotland',
    'Paisley, Scotland',
    'Elgin, Scotland',
    'Fort William, Scotland',
    'Oban, Scotland',
    'St. Andrews, Scotland',
    'Dunfermline, Scotland',
    'Fife, Scotland',
    'Aberfeldy, Scotland',
    'Glasgow, Scotland',
    'Edinburgh, Scotland',
    'Kirkwall, Scotland',
    'Isle of Skye, Scotland',
    'Thurso, Scotland',
    'Aviemore, Scotland',
    'Pitlochry, Scotland',
    'Galashiels, Scotland',
    'Birmingham, UK',
    'Liverpool, UK',
    'Manchester, UK',
    'Leeds, UK',
    'Sheffield, UK',
    'Newcastle upon Tyne, UK',
    'Cardiff, Wales',
    'Swansea, Wales',
    'Bristol, UK',
    'Norwich, UK',
    'Plymouth, UK',
    'Exeter, UK',
    'Cambridge, UK',
    'Oxford, UK',
    'Durham, UK',
    'Lincoln, UK',
    'Brighton, UK',
    'Eastbourne, UK',
    'Hastings, UK',
    'Chester, UK'
];


const vehicleReviews = [
    'The vehicle runs smoothly, and I couldn’t be happier with the performance!',
    'I bought a car last year, and it’s been reliable and fuel-efficient ever since.',
    'Mark in Hardyards was incredibly helpful in selecting the perfect car for my family.',
    'The service department at this dealership is top-notch. Highly recommended!',
    'My new SUV exceeded all my expectations, both in terms of comfort and power.',
    'I was impressed with the after-sale service, especially from Alexa in Mintlaw.',
    'Best car buying experience ever! The team was professional, and I got exactly what I wanted.',
    'The staff was knowledgeable, and they made the whole process smooth and stress-free.',
    'Mark and Anna made sure I got the best deal possible on my vehicle. Great team!',
    'The vehicle has all the features I was looking for, and it’s been a joy to drive.',
    'I had a great experience purchasing my car, especially with the help of Mark from Peterhead.',
    'Couldn’t be more pleased with the service I received. My vehicle runs perfectly!',
    'Excellent value for money! The vehicle is reliable and economical.',
    'The team in Hardyards, Mintlaw really went the extra mile to make sure I was satisfied with my purchase.',
    'Alexa provided outstanding customer service. I would recommend her to anyone in the area.',
    'Bought a used car, and it’s been flawless. Great service from start to finish!',
    'The dealership in Mintlaw had a great selection, and the staff was very helpful.',
    'Mark from Peterhead really knows his vehicles. I’m thrilled with my purchase!',
    'The car drives like a dream, and I haven’t had any issues since I bought it.',
    'Amazing service! The team helped me find the perfect vehicle for my needs.',
    'I’m incredibly satisfied with my vehicle purchase. Alexa made sure everything went smoothly.',
    'The customer service at the dealership was outstanding. They made buying a car easy.',
    'I love my new vehicle! It’s exactly what I was looking for, and it runs beautifully.',
    'Alexa provided excellent advice, and I’m extremely happy with my purchase.',
    'The dealership was fantastic, with a great range of vehicles to choose from.',
    'The quality of the vehicle is superb, and the service from Mark and Anna was second to none.',
    'I had an amazing experience with this dealership. The staff was friendly and helpful.',
    'Alexa in Hardyards took the time to explain all the options and helped me make the right choice.',
    'I’m so happy with my vehicle purchase! It’s reliable, comfortable, and affordable.',
    'The team in Peterhead was incredibly professional and made the buying process seamless.',
    'The vehicle I purchased is perfect for my daily commute. Couldn’t be happier with my choice.',
    'I’ve been recommending this dealership to everyone I know. Mark was fantastic!',
    'The after-sales service has been brilliant. I had a few questions, and they were answered promptly.',
    'I was able to find the perfect family vehicle with the help of Alexa and Anna.',
    'The dealership had a fantastic selection of vehicles. I found exactly what I was looking for.',
    'My car is performing great, and the fuel efficiency is excellent. Thanks to Mark and Anna for the help!',
    'It’s been months, and my car is still driving like it’s brand new. Fantastic experience!',
    'Mark at Hardyards really knows his stuff. His advice was invaluable in choosing the right vehicle.',
    'I was impressed with the professionalism of the team in Mintlaw. They made everything easy.',
    'The vehicle I purchased is perfect, and I love the additional features!',
    'Couldn’t ask for a better car buying experience. The team at Peterhead is amazing!',
    'Mark was patient and informative, helping me find the right vehicle for my budget.',
    'My SUV is a beast on the road, and I’m loving every moment of driving it.',
    'The customer service from Alexa and Anna was exceptional. I’ll definitely be back for my next car.',
    'I’m absolutely in love with my new car! Great quality and fantastic service from the dealership.',
    'The vehicle has been incredibly reliable, and the whole buying experience was hassle-free.',
    'Alexa ensured I got the best financing options and was there every step of the way.',
    'From start to finish, the experience was flawless. The team in Hardyards made everything easy.',
    'The vehicle drives like a dream, and I’m grateful to Mark for his expert advice.',
    'This dealership is second to none! The service was excellent, and the vehicle is top-quality.'
];


const dateTimes = [
    '2024-09-20 09:12:34',
    '2024-09-21 14:53:28',
    '2024-09-22 07:45:19',
    '2024-09-23 11:37:50',
    '2024-09-24 19:28:41',
    '2024-09-25 08:59:12',
    '2024-09-26 13:15:45',
    '2024-09-27 17:30:22',
    '2024-09-28 09:52:31',
    '2024-09-29 16:45:55',
    '2024-09-30 10:12:49',
    '2024-10-01 18:05:17',
    '2024-10-02 14:29:30',
    '2024-10-03 08:45:12',
    '2024-09-20 16:11:22',
    '2024-09-21 20:14:55',
    '2024-09-22 11:23:43',
    '2024-09-23 13:19:28',
    '2024-09-24 22:38:05',
    '2024-09-25 07:56:17',
    '2024-09-26 17:11:02',
    '2024-09-27 21:32:38',
    '2024-09-28 18:23:44',
    '2024-09-29 13:39:57',
    '2024-09-30 08:28:09',
    '2024-10-01 12:54:33',
    '2024-10-02 10:22:13',
    '2024-10-03 19:08:27',
    '2024-09-20 15:43:19',
    '2024-09-21 09:45:58',
    '2024-09-22 08:13:44',
    '2024-09-23 16:22:37',
    '2024-09-24 12:58:51',
    '2024-09-25 09:37:14',
    '2024-09-26 20:49:35',
    '2024-09-27 14:03:23',
    '2024-09-28 07:24:58',
    '2024-09-29 22:17:40',
    '2024-09-30 11:59:32',
    '2024-10-01 09:07:56',
    '2024-10-02 17:48:24',
    '2024-10-03 11:11:14',
    '2024-09-20 19:45:32',
    '2024-09-21 12:59:47',
    '2024-09-22 19:33:22',
    '2024-09-23 07:14:25',
    '2024-09-24 16:37:08',
    '2024-09-25 14:25:45',
    '2024-09-26 12:31:54',
    '2024-09-27 10:45:18',
    '2024-09-28 14:56:42',
    '2024-09-29 08:14:33'
];



  // Generate 30 reviews
  const generatedReviews = [];
  for (let i = 0; i < 50; i++) {
    const randomIndex = Math.floor(Math.random() * names.length); // Ensures same index for review and date
    const randomName = names[randomIndex];
    const randomLocation = locations[randomIndex];
    const randomReview = vehicleReviews[randomIndex];
    const randomDateTime = dateTimes[randomIndex]; // Corresponding dateTime

    // const [date, time] = randomDateTime.split(' '); // Split into date and time
    
    generatedReviews.push({
      name: randomName,
      location: randomLocation,
      review: randomReview,
      datetime: randomDateTime
    });
  }
  
  return generatedReviews;
};

const ViewProductsBasedOnMake = () => {
  // eslint-disable-next-line
  const [reviews, setReviews] = useState(generateStaticReviews());

  return (
    <Container maxWidth={false} style={{ marginTop: '100px', paddingLeft: '5px', paddingRight: '5px' }}>
        <div className="rating-summary" style={{display:'none'}}>
    <div className="overall-rating">
      <span className="stars">★★★★★</span>
      <span className="overall-score">5 out of 5</span>
    </div>
   
    
    <div className="rating-breakdown">
      <div className="rating-item">
        <span>5 star</span>
        <div className="rating-bar">
          <div className="filled-bar" style={{ width: '94%' }}></div>
        </div>
        <span>94%</span>
      </div>

      <div className="rating-item">
        <span>4 star</span>
        <div className="rating-bar">
          <div className="filled-bar" style={{ width: '3%' }}></div>
        </div>
        <span>3%</span>
      </div>

      <div className="rating-item">
        <span>3 star</span>
        <div className="rating-bar">
          <div className="filled-bar" style={{ width: '1%' }}></div>
        </div>
        <span>1%</span>
      </div>

      <div className="rating-item">
        <span>2 star</span>
        <div className="rating-bar">
          <div className="filled-bar" style={{ width: '0%' }}></div>
        </div>
        <span>0%</span>
      </div>

      <div className="rating-item">
        <span>1 star</span>
        <div className="rating-bar">
          <div className="filled-bar" style={{ width: '1%' }}></div>
        </div>
        <span>1%</span>
      </div>
    </div>
  </div>
    <div className="review-section">
      <h2 className="review-title">Customer Reviews</h2>
      <div className="review-list">
        {reviews.map((review, index) => (
          <div key={index} className={`review-card ${index === 0 ? 'featured-review' : ''}`}>
            <div className="review-header">
              <strong className="review-name">{review.name.toLowerCase()} - <small>{review.location}</small></strong>
              <span className="review-date">{review.vehicleReviews} <small>{review.datetime}</small></span>
            </div>
            <span className="verified">VERIFIED PURCHASE</span>
            <p className="review-text">"{review.review}"</p>
            <div className="review-rating">
              <span className="stars">★★★★★</span>
              <span className="rating-score">5.0</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Container>
  
  
  
  );
};

export default ViewProductsBasedOnMake;
