// configDetails.js
const configDetails = {
    swFormatted: "+44 33 0001 1044",
    sw: '+443300011044',
    wappsw: "+443300011044",
    wappswFormatted: "+44 33 0001 1044",
    email: "autodealsts@gmail.com",
    spain: "+44 33 0001 1044",
    infoEmail: 'autodealsts@gmail.com',
    emailSpanish:'autodealsts@gmail.com',
    organizationnumber: '559447-0790 ',
    vatnumber: 'SC823023'
};

export default configDetails;



