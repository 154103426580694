import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HomePageLeft from '../components/HomePageLeft';
import GalleryProducts from './galleryProducts';
import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import SendOfferForm from './SendOfferForm';
import ContactPage from './ContactProduct';
//import configDetails from './../components/utils/ConfigDetails';

const MachineryDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [machinery, setMachinery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };
  

  useEffect(() => {
    const fetchMachinery = async () => {
      try {
        
const decodedTitle = decodeURIComponent(id);
        const response = await fetch(`https://autodealsts.co.uk/apiiiwebsite/get_product_by_id.php?id=${decodedTitle}`);
        const data = await response.json();
        console.log(decodedTitle);
        if (data.error) {
          setError(data.error);
        } else {
          setMachinery(data);
        }
      } catch (error) {
        setError('Failed to fetch machinery details.');
      } finally {
        setLoading(false);
      }
    };
   
    fetchMachinery();
  }, [id]);

  if (loading) {
    return <Typography variant="h6" style={{ marginTop: '105px', textAlign: 'center', justifyContent: 'center' }}>Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" style={{ marginTop: '105px', textAlign: 'center', justifyContent: 'center' }}>{error}</Typography>;
  }

  // const handleWhatsAppClick = () => {
  //   const phone = configDetails.wappsw; // WhatsApp phone number with country code
  //   const message = `Hello, I would like more information about ${machinery.title}`; // Custom message
  //   const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  //   window.open(url, '_blank'); // Open WhatsApp in a new tab
  // };

  const createMarkup = (text) => {
    // Replace periods followed by space or end of sentence with period and <br>
    let formattedText = text.replace(/\.(\s|$)/g, ".<br>");
    
    // Replace both \r\n (Windows newlines) and \n (Unix newlines) with <br>
    formattedText = formattedText.replace(/\r?\n/g, "<br>");
    
    return { __html: formattedText };
  };
  
  
  
  const buyitnow = (product) => {
    navigate(`/buy-now/${machinery.reference}`); // Convert category to lowercase and redirect
  };
  const reserve = (product) => {
    navigate(`/reserve/${machinery.reference}`);
  } 
  console.log(machinery.produs_adaugat_de);
  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
      {machinery ? (
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
            <div className="vehicle-details">
              <div className="vehicle-specifications" style={{color: 'white', background:'rgb(255, 50, 50)'}}>{t('VehicleSpecification')}</div>
              <div className='inlineDisplay'>
                <div className="sub-column-left">
                  <div className="gallery">
                    <GalleryProducts main_photo={machinery.main_photo} all_photos={machinery.all_photos} />
                  </div>
                </div>
                <div className="sub-column-right">
                  <h1 className='productH1'>{machinery.title}</h1>
                  <h2 className='productH2'>{t('Used')}<span style={{textTransform:'lowercase'}}>{machinery.category} </span>{t('ForSale')}</h2>
                  <div className='wrapperPretsiWhatsupp'>
                    <div className='machinerypret'> 
                      {machinery.price === '1' ? (
                        <span className='priceSpan'>{t('sold')}</span>
                      ) : machinery.price === '2' ? (
                        <span className='priceSpan'>{t('askforPrice')}</span>
                      ) : machinery.price === '3' ? (
                        <span className='priceSpan'>{t('reserved')}</span>
                      ) : machinery.price >= 10 ? (
                        <span className='priceSpan' style={{color:'rgb(255, 50, 50)'}}>
                          {formatPrice(machinery.price)} <span className='smallTax'>(TAX Incl.)</span>
                        </span>
                      ) : (
                        <span className='priceSpan' style={{color:'rgb(255, 50, 50)'}}>
                          {machinery.price}
                        </span>
                      )}
                    </div>
                    <div className='whatsuppProductPageButton' style={{display:''}}>
                    <Typography sx={{ textAlign: 'center', width: '100%', marginRight:'10p' }} onClick={buyitnow}  className='makethebestoffer'>Buy now</Typography>
                    <Typography sx={{ textAlign: 'center', width: '100%', display:'none'}}  onClick={reserve} className='makethebestoffer'>Reserve</Typography>
                      
                    </div>
                  </div>
                  <Typography className="body1"><strong>{t('Year')}:</strong> {machinery.year}</Typography>
                  <Typography className="body1" variant="body1"><strong>{t('Make')}:</strong> {machinery.make}</Typography>
                  <Typography className="body1" style={{display:'none'}}><strong>HP:</strong> {machinery.hp}</Typography>
                  <Typography className="body1" style={{display:''}}><strong>Miles:</strong> {machinery.milles}</Typography>
                  <Typography className="body1" style={{display:''}}><strong>Mot:</strong> {machinery.mot} </Typography>
                  <Typography className="body1" style={{display:''}}><strong>Transmission:</strong> {machinery.transmission} </Typography>
                  <Typography className="body1"><strong>{t('Reference')}:</strong> {machinery.reference}</Typography>
                  <div style={{borderBottom:'1px solid #00000036', marginTop:'5px', marginBottom:'5px'}}></div>
                 
                  <div className="descriptionPRoduct" dangerouslySetInnerHTML={createMarkup(machinery.description)}></div>
                  <div style={{borderBottom:'1px solid #00000036', marginTop:'5px', marginBottom:'5px'}}></div>
                  <p style={{display:'none'}}>
                    <Trans i18nKey="DeliveryEverywhere" components={{ br: <br /> }} /> 
                  </p>
                  <div style={{borderBottom:'1px solid #00000036', marginTop:'5px', marginBottom:'5px'}}>
                  
                  </div>
                  
                  <Accordion
                    sx={{
                      boxShadow: 'none', // Removes the box shadow
                      textAlign: 'center',  
                      justifyContent: 'center',
                      border: 'none', // Removes the border
                      '&:before': {
                        display: '', // Removes the divider line before the accordion
                      },
                    }}
                  >
                    <AccordionSummary style={{gap:'45px'}}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    > 
                      
                    </AccordionSummary>
                    ``
                    
                    <AccordionDetails style={{display:"none"}}>
                      <SendOfferForm reference={machinery.emailAdaugatDe}  />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="vehicle-specifications" style={{background:'#ff3232', color:'white', display:'none'}}>{t('DOyouwanttobuythiuscar')}</div>
              <div className='contactformforbuyFromProductsPage' style={{marginLeft:'10px' , display:'none'}}>
                <ContactPage referencedNumber={machinery.reference}  emailAdaugatDe={machinery.produs_adaugat_de} productTitle={machinery.title }/>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Typography variant="h6" style={{ marginTop: '105px!important' }}>Machinery not found.</Typography>
      )}
    </Container>
  );
};

export default MachineryDetails;
