import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './../components/ProductsHomePage.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const CategoryProducts = () => {
  const { category } = useParams(); // Get the category from URL parameters
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const { t } = useTranslation();
  const formatPrice = (price) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://bfmbglobal.com/apiiiwebsite/get_all_products.php?category=${category}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await response.json();
        setProducts(data.products); // Extract 'products' array from the API response
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]); // Add category as a dependency to re-fetch when it changes

  if (loading) {
    return <CircularProgress />;
  }
  const handleCardClick = (product) => {
    navigate(`/details/${product}`); // Convert category to lowercase and redirect
  };
  
  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={3} key={product.id} sx={{ width: '24.25%' }}>
          <div onClick={() => handleCardClick(product.id)}  style={{ cursor: 'pointer' }}>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image={product.main_photo} // Assuming 'main_photo' is the key for the main product photo
                alt={product.title}
                sx={{ objectFit: 'fill', height: '230px' }}
              />
              <CardContent style={{ padding: '0px' }}>
                <Typography gutterBottom variant="h5" component="div" className='classForTitleforProductsHomepage'>
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
    {product.price === '1' ? (
        <h1 style={{ color: 'gold', marginRight: '5px', marginTop: '0px' }}>
            {t('sold')}
        </h1>
    ) : product.price === '2' ? (
        <h1 style={{ color: 'gold', marginRight: '5px', marginTop: '0px' }}>
            {t('askforPrice')}
        </h1>
    ) : product.price === '3' ? (
        <h1 style={{ color: 'gold', marginRight: '5px', marginTop: '0px' }}>
            {t('reserved')}
        </h1>
    ) : product.price >= 10 ? (
        <>
            <h1 style={{ color: 'gold', marginRight: '5px', marginTop: '0px' }}>
                {formatPrice(product.price)}
            </h1>
            <small style={{ color: 'gold', fontWeight: 'bold', marginTop: '-10px' }}>
                (TAX Included)
            </small>
        </>
    ) : (
        <h1 style={{ color: 'gold', marginRight: '5px', marginTop: '0px' }}>
            {product.price}
        </h1>
    )}
</div>

                  <div style={{ marginLeft: '10px', marginTop: '-15px'  }}>
                    Reference: <span style={{ fontWeight: 'bold', color: 'black' }}>{product.reference}</span>
                  </div>
                  <div style={{ marginLeft: '10px', marginBottom: '20px' }}>
                    Year: <span style={{ fontWeight: 'bold', color: 'black' }}>{product.year}</span>
                  </div>
                 
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryProducts;
